@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes featureEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes whyItMattersEnter {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.feature-display {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.animate-feature-enter {
  animation: featureEnter 0.3s ease-out;
}

.animate-why-it-matters {
  opacity: 0;
  animation: whyItMattersEnter 0.3s ease-out forwards;
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

/* Custom scrollbar for webkit browsers */
.feature-display .overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.feature-display .overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.feature-display .overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.feature-display .overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.feature-display .overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

@media (max-width: 640px) {
  .feature-display .overflow-y-auto {
    -webkit-overflow-scrolling: touch;
  }
}

/* Add new mobile styles */
@media (max-width: 1023px) { /* lg breakpoint */
  .feature-display {
    height: auto !important; /* Override any fixed heights */
  }
  
  .feature-display .overflow-y-auto {
    overflow: visible !important; /* Remove scrolling on mobile */
  }
}
