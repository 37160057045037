.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}
		
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { 
  margin-bottom: 16px;
}

.material-shadow {
  box-shadow: 
      0px 0px 0px 1px rgba(3, 239, 254, 0.04),   /* Light blue with very low opacity */
      0px 1px 1px -0.5px rgba(35, 43, 45, 0.04), /* Darker tone, small blur and negative spread */
      0px 3px 3px -1.5px rgba(34, 34, 46, 0.04), /* Similar to above with increased blur and spread */
      0px 6px 6px -3px rgba(34, 34, 46, 0.04),   /* Increase in offsets and negative spread */
      0px 12px 12px -6px rgba(3, 239, 254, 0.04),/* Light blue with significant offset and negative spread */
      0px 24px 24px -12px rgba(3, 239, 254, 0.04);/* Maximum offset with corresponding blur and negative spread */
}

.sidebar {
  transition: width 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .sidebar {
    width: 20%;
  }
}

@media (min-width: 1024px) {
  .sidebar {
    width: 16.666667%;
  }
}

@media (min-width: 1280px) {
  .sidebar {
    width: 16.666667%;
  }
}

.sidebar-content {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

section {
  transition: margin-left 0.3s ease-in-out;
}

/* @media (min-width: 999px) {
  section {
    margin-left: 40%;
  }
}

@media (min-width: 1080px) {
  section {
    margin-left: 35%;
  }
}

@media (min-width: 1280px) {
  section {
    margin-left: 30%;
  }
} */